<template>
  <iui-container type="css-flex">
    <i-row>
      <i-col style="padding-right:10px;box-sizing:border-box">
        <iui-container type="jqgrid" :id="sheetId" style="width:99.9%;" />
      </i-col>
      <i-col>
        <iui-container type="table" v-if="isOtis" :headerWidth="width200">
          <i-row>
            <i-col-header rowspan="6" width="70px">OSE</i-col-header>
            <i-col-header>사업자번호(주민번호)</i-col-header>
            <i-col>
              <iui-text :value="memberInfo.businessNo" :enable="false" width="100px" />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>상호(이름)</i-col-header>
            <i-col>
              <iui-text :value="memberInfo.tradeName" :enable="false" />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>대표자</i-col-header>
            <i-col>
              <iui-text :value="memberInfo.repntName" :enable="false" width="100px" />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>주소</i-col-header>
            <i-col>
              <iui-text :value="memberInfo.address" :enable="false" />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>서명일시</i-col-header>
            <i-col>
              <iui-text v-if="isSign" :value="memberInfo.signDt" :enable="false" />
              <iui-text v-else value="미서명" :enable="false" />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>방법 / IP</i-col-header>
            <i-col>
              <iui-text v-if="isSign" :value="memberInfo.signMthNm" :enable="false" />
              <iui-text v-if="isSign" :value="memberInfo.accesIp" :enable="false" />
              <iui-text v-else value="미서명" :enable="false" />
            </i-col>
          </i-row>
        </iui-container>
        <iui-container type="table" v-else-if="isCustomer" :headerWidth="width200">
          <i-row>
            <i-col-header rowspan="4" width="70px">
              {{ memberInfo.tradeTypeBcn }}
            </i-col-header>
            <i-col-header>사업자번호(주민번호)/거래처번호</i-col-header>
            <i-col>
              <iui-text :value="memberInfo.businessNo2" :enable="false" />
              <iui-text :value="memberInfo.tradeNo" :enable="false" width="100px" />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>상호(이름)</i-col-header>
            <i-col>
              <iui-text :value="memberInfo.tradeName" :enable="false" />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>대표자</i-col-header>
            <i-col>
              <iui-text :value="memberInfo.repntName" :enable="false" width="100px" />
              <!-- <iui-button color="blue" v-show="isCanUrlCopy" v-clipboard:copy="memberInfo.shrtenUrl" v-clipboard:success="onCopy" v-clipboard:error="onError">URL복사</iui-button> -->
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>주소</i-col-header>
            <i-col>
              <iui-text :value="memberInfo.address" :enable="false" />
            </i-col>
          </i-row>
        </iui-container>
        <iui-container type="table" v-else :headerWidth="width200">
          <i-row>
            <i-col-header rowspan="8" width="70px">
              {{ memberInfo.tradeTypeBcn }}
            </i-col-header>
            <i-col-header>사업자번호(주민번호)/거래처번호</i-col-header>
            <i-col>
              <iui-text :value="memberInfo.businessNo2" :enable="false" />
              <iui-text :value="memberInfo.tradeNo" :enable="false" width="100px" />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>상호(이름)</i-col-header>
            <i-col>
              <iui-text :value="memberInfo.tradeName" :enable="false" />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>대표자</i-col-header>
            <i-col>
              <iui-text :value="memberInfo.repntName" :enable="false" width="100px" />
              <iui-button
                color="blue"
                v-show="isCanUrlCopy"
                v-clipboard:copy="memberInfo.shrtenUrl"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                >URL복사</iui-button
              >
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>주소</i-col-header>
            <i-col>
              <iui-text :value="memberInfo.address" :enable="false" />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>유지관리업/<br />건설업등록번호</i-col-header>
            <i-col>
              <iui-text :value="memberInfo.licenseNo" :enable="false" />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>모바일번호/Email</i-col-header>
            <i-col>
              <iui-text :value="memberInfo.mphone" :enable="false" />
              <iui-text :value="memberInfo.email" :enable="false" />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>서명일시</i-col-header>
            <i-col>
              <iui-text v-if="isSign" :value="memberInfo.signDt" :enable="false" />
              <iui-text v-else value="미서명" :enable="false" />
            </i-col>
          </i-row>
          <i-row>
            <i-col-header>방법 / IP</i-col-header>
            <i-col>
              <iui-text v-if="isSign" :value="memberInfo.signMthNm" :enable="false" />
              <iui-text v-if="isSign" :value="memberInfo.accesIp" :enable="false" />
              <iui-text v-else value="미서명" :enable="false" />
            </i-col>
          </i-row>
        </iui-container>
      </i-col>
    </i-row>
  </iui-container>
</template>

<script>
export default {
  components: {},
  beforeCreate() {
    $mapGetters(this, ['estimateList', 'estimateMemberList', 'isEstEmp']);
  },
  created() {
    this.addEvent({name: 'EstimateMembers_Load', func: this.onLoad});
  },
  data() {
    return {
      sheetId: 'sheet' + this._uid,
      listBoxLoading: false,

      theme: 'bullet',
      width60: '60px',
      width80: '80px',
      width100: '100px',
      width170: '170px',
      width200: '200px',

      memberInfo: {
        estNo: '',
        revisionNo: '',
        tradeNo: '',
        tradeSfx: '',
        mainCustFlag: '',
        tradeType: '',
        tradeTypeBcn: '',
        tradeOrder: '',
        billToFlag: '',
        taxCode: '',
        taxCodeQo: '',
        taxValue: '',
        mphone: '',
        email: '',
        custRate: '',
        billRate: '',
        notiFlag: '',
        signType: '',
        signTypeSgn: '',
        businessNo: '',
        businessNo2: '',
        tradeName: '',
        repntName: '',
        address: '',
        licenseNo: '',
        signOrReject: '',
        ctrtNo: '',
        ctrtOdr: '',
        ctrtDivCd: '',
        signDt: '',
        signMthCd: '',
        signMthNm: '',
        accesIp: '',
        personId: '',
        estEmpId: '',
        shrtenUrl: '',
      },

      otisPage: false,
    };
  },
  mounted() {
    const me = this;
    $('#' + me.sheetId).jqGrid({
      height: 150,
      width: 800,
      colModel: [
        {name: 'checkContNo', label: '계약번호', width: 0, align: 'center', hidden: true},
        {name: 'checkSeq', label: '계약차수', width: 0, align: 'center', hidden: true},
        {name: 'ctrtNo', label: 'ctrtNo', width: 0, hidden: true},
        {name: 'ctrtOdr', label: 'ctrtOdr', width: 0, hidden: true},
        {name: 'ctrtDivCd', label: 'ctrtDivCd', width: 0, hidden: true},
        {name: 'tradeNo', label: '거래선번호', width: 0, align: 'center', hidden: true},
        {name: 'tradeSfx', label: '거래선SFX', width: 0, align: 'center', hidden: true},
        {name: 'tradeType', label: '구분코드', width: 0, align: 'center', hidden: true},
        {name: 'tradeTypeBcn', label: '구분', width: 30, align: 'center'},
        {name: 'mainCustFlag', label: '대표사여부', width: 40, align: 'center'},
        {name: 'tradeName', label: '상호(법인)/<br/>이름(개인/개인사업자)', width: 80, align: 'left'},
        {name: 'repntName', label: '대표자', width: 70, align: 'center'},
        {name: 'billRate', label: '도급/하도급<br/>계약금액 비율', width: 50, template: 'number'},
        {name: 'signOrReject', label: '서명/반송', width: 50, align: 'center'},
      ],
      iconSet: 'fontAwesome',
      idPrefix: 'g1_',
      selectionMode: 'singlerow',
      rownumbers: true,
      // eslint-disable-next-line
      onCellSelect: function(rowId, iCol, cellContent, e) {
        var rowData = $('#' + me.sheetId).jqGrid('getRowData', rowId);
        me.onMemberInfo(rowData);
      },
    });
    window.addEventListener('resize', this.onResize);
    this.onResize();
    this.onLoad();
  },
  methods: {
    // eslint-disable-next-line
    onResize(event) {
      // console.log('window has been resized', event);
      var $grid = $('#' + this.sheetId);
      var pobj = $grid
        .closest('.ui-jqgrid')
        .parent()
        .parent();
      var newWidth = pobj.width();
      $grid.jqGrid('setGridWidth', newWidth, true);
      var newHeight = pobj.height();
      $grid.jqGrid('setGridHeight', newHeight - 70, true);
    },
    onLoad(param) {
      if (param !== undefined) {
        this.otisPage = param.otisPage;
      }
      console.log('EstimateMembers_Load');
      setTimeout(() => {
        this.bindData();
      }, 100);
    },
    bindData() {
      $('#' + this.sheetId)
        .clearGridData(true)
        .setGridParam({data: this.estimateMemberList})
        .trigger('reloadGrid');
      var ids = $('#' + this.sheetId).jqGrid('getDataIDs');
      if (ids && ids.length > 0) {
        $('#' + this.sheetId).jqGrid('setSelection', ids[0], true);
        var rowData = $('#' + this.sheetId).jqGrid('getRowData', ids[0]);
        if (rowData.ctrtNo !== undefined) {
          this.onMemberInfo(rowData);
        }
      } else {
        this.memberInfo.estNo = '';
        this.memberInfo.revisionNo = '';
        this.memberInfo.tradeNo = '';
        this.memberInfo.tradeSfx = '';
        this.memberInfo.mainCustFlag = '';
        this.memberInfo.tradeType = '';
        this.memberInfo.tradeTypeBcn = '';
        this.memberInfo.tradeOrder = '';
        this.memberInfo.billToFlag = '';
        this.memberInfo.taxCode = '';
        this.memberInfo.taxCodeQo = '';
        this.memberInfo.taxValue = '';
        this.memberInfo.mphone = '';
        this.memberInfo.email = '';
        this.memberInfo.custRate = '';
        this.memberInfo.billRate = '';
        this.memberInfo.notiFlag = '';
        this.memberInfo.signType = '';
        this.memberInfo.signTypeSgn = '';
        this.memberInfo.businessNo = '';
        this.memberInfo.businessNo2 = '';
        this.memberInfo.tradeName = '';
        this.memberInfo.repntName = '';
        this.memberInfo.address = '';
        this.memberInfo.licenseNo = '';
        this.memberInfo.signOrReject = '';
        this.memberInfo.ctrtNo = '';
        this.memberInfo.ctrtOdr = '';
        this.memberInfo.ctrtDivCd = '';
        this.memberInfo.signDt = '';
        this.memberInfo.signMthCd = '';
        this.memberInfo.signMthNm = '';
        this.memberInfo.accesIp = '';
        this.memberInfo.personId = '';
        this.memberInfo.estEmpId = '';
        this.memberInfo.shrtenUrl = '';
      }
    },
    async onMemberInfo(rowData) {
      let param = {
        ctrtNo: rowData.ctrtNo,
        ctrtOdr: rowData.ctrtOdr,
        ctrtDivCd: rowData.ctrtDivCd,
        tradeNo: rowData.tradeNo,
        tradeSfx: rowData.tradeSfx,
      };
      let response = await axios.post('/estimate/selectMemberInfo', param);

      this.memberInfo.ctrtNo = response.data.ctrtNo;
      this.memberInfo.ctrtOdr = response.data.ctrtOdr;
      this.memberInfo.ctrtDivCd = response.data.ctrtDivCd;
      this.memberInfo.tradeNo = response.data.tradeNo;
      this.memberInfo.tradeType = response.data.tradeType;
      this.memberInfo.tradeTypeBcn = response.data.tradeTypeBcn;
      this.memberInfo.tradeName = response.data.tradeName;
      this.memberInfo.repntName = response.data.repntName;
      this.memberInfo.mphone = response.data.mphone;
      this.memberInfo.email = response.data.email;
      this.memberInfo.buyagntNm = response.data.buyagntNm;
      this.memberInfo.businessNo = response.data.businessNo;
      this.memberInfo.businessNo2 = response.data.businessNo2;
      this.memberInfo.address = response.data.address;
      this.memberInfo.licenseNo = response.data.licenseNo;
      this.memberInfo.signDt = response.data.signDt;
      this.memberInfo.signMthCd = response.data.signMthCd;
      this.memberInfo.signMthNm = response.data.signMthNm;
      this.memberInfo.accesIp = response.data.accesIp;
      this.memberInfo.shrtenUrl = response.data.shrtenUrl;
    },
    onCopy() {
      this.$alert(
        '클립보드 복사에 성공하였습니다.<br/>브라우저에서 새로운 탭을 열고 주소창에 붙여넣기 하여 사용하세요.'
      );
    },
    onError(e) {
      this.$alert('클립보드 복사에 실패하였습니다.');
      console.log(e);
    },
  },
  computed: {
    isOtis() {
      return this.memberInfo.tradeType == 'OTIS';
    },
    isCustomer() {
      return this.memberInfo.tradeType == 'CUST';
    },
    isSign() {
      return this.memberInfo.signDt;
    },
    isCanUrlCopy() {
      return (
        ($isAdmin() || this.isEstEmp) &&
        this.otisPage &&
        this.memberInfo.shrtenUrl != null &&
        this.memberInfo.shrtenUrl != ''
      );
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style></style>
