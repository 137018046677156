<template>
  <div id="wrap_customer">
    <span
      ref="server"
      style="border:3px solid white;padding:5px 20px 10px 20px;font-size:20px;line-height:30px;position:absolute;top:180px;margin:auto;background-color:red;color:white;display:none"
    ></span>
    <div id="contents_customer" >
      <div class="logo_section">
        <h1 class="logo"><span class="blind">Otis Service Experts</span></h1>
        <div class="footer_info">
          <div class="login_c mb20">
            <select
              onchange="if(this.selectedIndex>0) { window.open(this[this.selectedIndex].value);this.selectedIndex=0; }"
            >
              <option value="" selected="selected">OTIS Family Site 바로가기</option>
              <option value="https://www.otis.com/ko/kr/contact-us">영업팀에 문의</option>
              <option value="https://www.otis.com/ko/kr/products-services/otis-signature-service"
                >오티스 시그니처 서비스</option
              >
              <option value="https://www.otis.com/ko/kr/products-services/services/safety-regulation"
                >정밀안전검사</option
              >
              <option value="https://www.otis.com/ko/kr/products-services/modernization-upgrades/modernization"
                >교체검사</option
              >
            </select>
          </div>
          <span class="copy">ⓒ 2024 Otis Service Experts Ltd. 서울 강동구 성내로6길 11, 2층(성내동, 삼원타워)</span>

          <a
            href="https://www.otis.com/ko/kr/privacy-policy/"
            target="_blank"
            title="개인정보보호정책"
            ><span>개인정보보호정책</span></a
          >

          <div style="color:white;position:absolute; width:1100px;margin-top:20px;font-size:12px">
            Otis의 개인정보 처리방침에 관해 전반적으로 궁금한 사항이 있으시면 이메일(<a href="mailto:privacy@otis-se.com">privacy@otis-se.com</a>) 또는<br/>
            전화(1661-6114)로 문의하시기 바랍니다. 또한 Otis 엘리베이터 코리아에 문의하실 연락처는 다음과 같습니다. <br/>
            개인정보 보호책임자: 김종성 / 담당 부서: 법무팀 / 전화: 1661-6114
          </div>
        </div>
      </div>
      <div class="login_section">
        <h2 class="login"><span class="blind">LOGIN</span></h2>
        <ul class="login_tab">
          <li @click="tabChange(1)"><a id="link1" href="#" class="on">ID</a></li>
          <!--<li @click="tabChange(2)"><a id="link2" href="#">PASS</a></li>-->
          <li @click="tabChange(3)"><a id="link3" href="#">공동인증서</a></li>
        </ul>
        <div id="tab1">
          <input type="hidden" name="pgmCd" value="201900" />
          <div class="login_c mt10">
            <input type="text" name="id" @keypress="loginOnEnter" value="" placeholder="아이디를 입력하세요." />
          </div>
          <div class="login_c mt10">
            <input
              type="password"
              name="password"
              @keypress="loginOnEnter"
              value=""
              placeholder="비밀번호를 입력하세요."
            />
          </div>
          <div class="login_c_txt">
            <input type="checkbox" id="c1" /><label for="c1"><span></span>아이디 저장</label>
          </div>
          <a href="#" class="btn_login01" @click="login">LOGIN</a>
          <div class="link">
            <a href="#" @click="idFind" title="아이디찾기"><span>아이디찾기</span></a>
            <a href="#" @click="pwFind" title="비밀번호찾기"><span>비밀번호찾기</span></a>
            <a href="#" @click="openManual" title="사용자매뉴얼 새 창으로 이동"><span>사용자매뉴얼</span></a>
          </div>
        </div>
        <div id="tab2" style="display:none">
          <div class="login_box">
            <ul class="p_log">
              <li>
                <input type="radio" id="rx1" disabled="disabled" /><label for="rx1"
                  ><span></span>법인사업자(불가)</label
                >
              </li>
              <li>
                <input type="radio" id="rx2" checked="true" disabled="disabled" /><label for="rx2"
                  ><span></span>개인/개인사업자</label
                >
              </li>
            </ul>
            <a href="#" class="btn_login01" @click="onPassLogin">PASS 인증 로그인</a>
            <a href="#" @click="openManual" class="btn_manual mt10">사용자 매뉴얼</a>
          </div>
        </div>
        <div id="tab3" style="display:none">
          <div class="login_box">
            <ul class="c_log">
              <li>
                <input type="radio" id="rcert1" name="rcert" checked="true" @click="certTypeChange" /><label
                  for="rcert1"
                  ><span></span>법인(개인)사업자</label
                >
              </li>
              <li>
                <input type="radio" id="rcert2" name="rcert" @click="certTypeChange" /><label for="rcert2"
                  ><span></span>개인</label
                >
              </li>
            </ul>
            <div class="login_c mb10" id="inputCertType1">
              <input
                type="text"
                id="inputBusinessNo"
                maxlength="10"
                placeholder="사업자등록번호( - 없이 입력해 주세요)"
              />
            </div>
            <div class="login_c mb10" id="inputCertType2" style="display:none">
              <input
                type="text"
                id="personId1"
                maxlength="6"
                style="margin-right:6px; width:189px"
                placeholder="주민번호 앞자리"
              /><input type="text" id="personId2" maxlength="7" style="width:189px" placeholder="주민번호 뒷자리" />
            </div>
            <a href="#" class="btn_login01" @click="onSecuKitLogin">공동인증서 로그인</a>
            <a href="#" @click="openManual" class="btn_manual mt10">사용자 매뉴얼</a>
          </div>
        </div>
        <ul class="board_tab">
          <li id="boardTab1" @click="boardTabChange(1)" class="select"><a href="#">공지사항</a></li>
          <li id="boardTab2" @click="boardTabChange(2)"><a href="#">자료실</a></li>
        </ul>
        <div id="boardTabContent1" style="min-height:108px">
          <ul class="board_list" v-if="notiBoardList.length == 0">
            <li><a href="#" class="tit">공지사항에 등록된 데이터가 없습니다.</a></li>
          </ul>
          <ul class="board_list" v-else>
            <li v-for="(item, index) in notiBoardList" :key="index">
              <a href="#" class="tit" @click="onBoardView('NOTI', item.natNo)">{{ item.tl }}</a
              ><span class="date">{{ item.rgsDttm }}</span>
            </li>
          </ul>
        </div>
        <div id="boardTabContent2" style="display:none;min-height:108px">
          <ul class="board_list" v-if="fileBoardList.length == 0">
            <li><a href="#" class="tit">자료실에 등록된 데이터가 없습니다.</a></li>
          </ul>
          <ul class="board_list" v-else>
            <li v-for="(item, index) in fileBoardList" :key="index">
              <a href="#" class="tit" @click="onBoardView('FILE', item.natNo)">{{ item.tl }}</a
              ><span class="date">{{ item.rgsDttm }}</span>
            </li>
          </ul>
        </div>
        <div class="btn_guid_box">
          <a href="#" onclick="javascript:openHelp('/m/help/help_pc_sign.html')" class="btn_guid">전자서명</a>
          <a href="#" onclick="javascript:openHelp('/m/help/help_pc_stamptax.html')" class="btn_guid">인지세안내</a>
          <a href="#" onclick="javascript:openHelp('/m/help/help_pc_login.html')" class="btn_guid">LOGIN안내</a>
          <a href="#" onclick="javascript:openHelp('https://www.signgate.com/cert/certx.html')" class="btn_guid">인증서이동복사</a>
        </div>
      </div>
    </div>

    <iui-modal-old :name="boardViewModalId" :title="'게시판 조회'" :btns="modalBtns1" width="800px" height="600px">
      <BoardView :natDs="board.natDs" :natNo="board.natNo" />
    </iui-modal-old>

    <!-- 21-02-02 이동원 추가 -->
    <iui-modal-old name="idFind" title="아이디찾기" width="600px" height="550px">
      <UserIdFind />
    </iui-modal-old>

    <iui-modal-old name="pwFind" title="비밀번호찾기" width="600px" height="550px">
      <UserPwFind :userId="searchId" />
    </iui-modal-old>

    <iui-modal-old name="selectTradeNo" title="로그인 거래선 선택" width="400px" height="300px">
      <SelectTradeNo :tradeNoList="tradeNoList" :callback="onSecuKitLogin" />
    </iui-modal-old>
    <!--GJDE-->
    <iui-modal-old name="selectGjdeTradeNo" title="로그인 GJDE 거래선 선택" width="600px" height="300px">
      <SelectGjdeTradeNo :gjdeTradeNoList="gjdeTradeNoList" :callback="gjdeInitLogin" />
    </iui-modal-old>
  </div>
</template>

<script>
import {mapActions, mapMutations} from 'vuex';
import BoardView from '../common/board/components/BoardViewModal.vue';
import UserIdFind from '@/view/user/find/UserIdFind.vue';
import UserPwFind from '@/view/user/find/UserPwFind.vue';
import SelectTradeNo from '@/view/user/find/SelectTradeNo.vue';
import SelectGjdeTradeNo from '@/view/user/find/SelectGjdeTradeNo.vue';
// import {Datepicker, Timepicker, DatetimePicker} from '@livelybone/vue-datepicker';
import VueCookies from 'vue-cookies';
export default {
  name: 'Login',
  components: {
    BoardView,
    UserIdFind,
    UserPwFind,
    SelectTradeNo,
    SelectGjdeTradeNo,
  },
  data: function() {
    return {
      manualUrl:
        process.env.VUE_APP_BASE_URL + '/m/manual/전자계약_사용자매뉴얼_20210407_v1.0-OSEP및고객(PC-Mobile).pdf',
      webManualUrl: process.env.VUE_APP_BASE_URL + '/m/manual/manual01.html',
      home: 'Home',
      example: 'Example',
      menuList: [],
      loading: false,
      modalBtns: [{name: '확인', callback: this.onJoinConfirm}],
      modalBtns2: [{name: '확인', callback: this.onSaveCompany}],
      oldTabIndex: 1,
      certTypeIndex: 1,
      boardTabIndex: 1,

      boardViewModalId: 'boardViewModal' + this._uid,
      modalBtns1: [],
      board: {
        natDs: '',
        natNo: 0,
      },
      notiBoardList: [],
      fileBoardList: [],

      kmCertPem: '',
      loginToken: '',

      Identify: {
        LoginId: '',
        UserSignCert: '',
        EncryptedSessionKey: '',
        EncryptedUserRandomNumber: '',
        Message: '',
        SecuKit_PKCS7SignedMessage: '',
      },
      searchId: '',
      tradeNoList: [],
      //GJDE
      gjdeTradeNoList : []
    };
  },
  mounted() {
    this.checkAccessDeviceType();
    this.onSearchNotiBoardList();
    this.onSearchFileBoardList();

    // 2024.08.12 선영욱 모바일 분기 때문에 해당 내용 주석 처리. 모바일이 아니면 checkAccessDeviceType에서 this.$secuKit.checkReady() 호출하고 있음.
    //this.$secuKit.checkReady();

    var servername = '';
    if (location.host.indexOf('econtracttest') >= 0) {
      servername = 'OSE 개 발 서 버 외부';
    }
    // eslint-disable-next-line
    else if (location.host.indexOf('econtract') >= 0) {
    } else {
      servername = 'OSE LOCAL 외부';
    }
    if (servername != '') {
      this.$refs.server.innerHTML = servername;
      this.$refs.server.style.display = 'block';
    }

    var saveId = VueCookies.get('saveId');
    if (saveId !== undefined && saveId != null && saveId != '') {
      document.getElementsByName('id')[0].value = saveId;
      document.getElementById('c1').checked = true;
    }
  },
  // components:{Datepicker, Timepicker, DatetimePicker},
  methods: {
    ...mapMutations('login', ['setPage']),
    ...mapActions('login', ['doLogin']),
    async onBoardView(natDs, natNo) {
      this.board.natDs = natDs;
      this.board.natNo = natNo;
      this.$modal.show(this.boardViewModalId);
    },
    async onSearchNotiBoardList() {
      let response = await axios.post('/boardCust/selectBoardCustList', {
        pgmCd: '201900',
        natDs: 'NOTI',
        frmNm: '',
      });
      var cutLen = 22;
      // eslint-disable-next-line
      response.data.forEach((element, index) => {
        if (element.tl.length > cutLen) {
          element.tl = element.tl.substring(0, cutLen) + '...';
        }
      });
      this.notiBoardList = response.data;
    },
    async onSearchFileBoardList() {
      let response = await axios.post('/boardCust/selectBoardCustList', {
        pgmCd: '201900',
        natDs: 'FILE',
        frmNm: '',
      });
      var cutLen = 22;
      // eslint-disable-next-line
      response.data.forEach((element, index) => {
        if (element.tl.length > cutLen) {
          element.tl = element.tl.substring(0, cutLen) + '...';
        }
      });
      this.no;
      this.fileBoardList = response.data;
    },
    boardTabChange(n) {
      if (this.boardTabIndex == n) return;

      document.getElementById('boardTab' + this.boardTabIndex).classList.remove('select');
      document.getElementById('boardTab' + n).classList.add('select');

      document.getElementById('boardTabContent' + this.boardTabIndex).style.display = 'none';
      document.getElementById('boardTabContent' + n).style.display = 'block';

      this.boardTabIndex = n;
    },
    tabChange(n) {
      if (this.oldTabIndex == n) return;

      document.getElementById('link' + this.oldTabIndex).classList.remove('on');
      document.getElementById('link' + n).classList.add('on');

      document.getElementById('tab' + this.oldTabIndex).style.display = 'none';
      document.getElementById('tab' + n).style.display = 'block';

      this.oldTabIndex = n;
    },
    certTypeChange() {
      var n = document.getElementById('rcert1').checked ? 1 : 2;
      if (this.certTypeIndex == n) return;

      document.getElementById('inputCertType' + this.certTypeIndex).style.display = 'none';
      document.getElementById('inputCertType' + n).style.display = 'block';

      this.certTypeIndex = n;
    },

    async loginOnEnter($event) {
      if ($event.keyCode == 13) {
        $event.preventDefault();
        this.login();
        return false;
      }
    },

    async login() {
      // const formData = this.getFormData();
      var objid = document.getElementsByName('id')[0];
      objid.value = $.trim(objid.value);
      const pgmCd = document.getElementsByName('pgmCd')[0].value;
      const id = objid.value;
      const password = document.getElementsByName('password')[0].value;

      if (objid.value.length < 7) {
        alert('거래선번호 7자리를 입력해주세요');
        return;
      }
      if (password.length < 1) {
        alert('비밀번호를 입력하세요');
        return;
      }
      const formData = {
        pgmCd: pgmCd,
        id: id,
        password: password,
        page: this.home,
      };

      this.loading = true;
      const listTradeItem  = await this.doLogin(formData);
      this.gjdeTradeNoList = listTradeItem;
      

      //GJDE 거래선 자리수 체크
      console.log("this.gjdeTradeNoList",this.gjdeTradeNoList);
      //console.log("this.gjdeTradeNoList.length",this.gjdeTradeNoList.length);
      if(typeof this.gjdeTradeNoList != 'undefined' && this.gjdeTradeNoList.length > 0){
        this.selectGjdeTradeNo();
        return;
      }
      
    },
    onJoinStep() {
      this.setPage('joinStep');
    },
    async checkAccessDeviceType() {
      const me = this;
      const response = await axios.get('/gw/getDeviceType').catch(function(error) {
        console.log('error', error);
        me.setPage('NetworkErr');
        return;
      });
      console.log(response.data);
      if (response.data != 'PC') {
        const response2 = await axios.get('/gw/getUrlByDeviceType');
        console.log(response2.data);
        window.document.location.href = response2.data;
      // } else {
      //   this.$secuKit.checkReady();
      }
      console.log('isTouchDevice = ' + this.isTouchDevice());
    },
    isTouchDevice() {
      if ('ontouchstart' in window || (window.DocumentTouch && document instanceof DocumentTouch)) {
        return true;
      }
      if (navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) {
        return true;
      }
      const prefixes = ['', '-webkit-', '-moz-', '-o-', '-ms-', ''];
      const mq = query => window.matchMedia(query).matches;
      return mq(['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join(''));
    },

    // 패스 로그인
    async onPassLogin() {
      var passResult = await this.$PASSCertificationP();
      if (passResult.result) {
        console.log('passResult : ' + JSON.stringify(passResult));

        // eslint-disable-next-line
        //let userTelNo = passResult.data.mobileNo;	// PASS 인증한 휴대폰 번호
        // PASS 전화번호 2개이상 오류 메세지 테스트용
        //let userTelNo = '01025049676';

        // const telChkResponse = await axios.post('/userInfo/searchDuplicateUseIdByTelNo',{telNo: userTelNo });	//인증완료된 번호로 검색
        // console.log("searchDuplicateUseIdByTelNo response : ",telChkResponse); // true(중복 O) or false(중복 X)

        // if (telChkResponse.data){
        // 	this.$alert("PASS 본인 인증에 사용하신 번호로 검색된 로그인ID(거래선번호)가 2개 이상 존재합니다. 다른 로그인 방식을 이용해 주십시오.<br>"
        // 	+"만일 다른 로그인 방식을 이용하여도 로그인을 하지 못할 시에는 영업담당자에게 문의해 주시길 바랍니다.");
        // 	return ;
        // }

        // 20210401 김연진 preCheckLogin 에 로직 통합

        var passNo = passResult.data.passNo;

        let response = await axios.post('/userInfo/preCheckLogin', {
          type: 'pass',
          data: passNo,
        });

        var loginTradeNo = response.data.tradeNo;
        /**GJDE 추가 PASS 는 커스터머 이므로 두개가 최고임 */
        var oldTradeNo = response.data.oldTradeNo; //GJDE
        console.log("loginTradeNo:",loginTradeNo);
        console.log("oldTradeNo:",response);
        if ((!loginTradeNo || loginTradeNo == '') && response.data.error == 'DUPLICATE') {
          this.tradeNoList = response.data.data;
         
          //이미 신거래선이 생성된거면 7자리는 지운다
          var tradeNoList_T = [];          

          if ( this.tradeNoList.length == 2) {
            tradeNoList_T = this.tradeNoList.filter(function (x){
              return (x.oldTradeNo.length > 7 && x.tradeNo==x.oldTradeNo);
            });
            this.tradeNoList = tradeNoList_T;
            console.log("tradeNoList_T",tradeNoList_T);
          }

          if ( this.tradeNoList.length == 1) {
            loginTradeNo = tradeNoList_T[0].tradeNo;
            oldTradeNo = tradeNoList_T[0].oldTradeNo;
          }          
        }
        /**GJDE 추가 */
        if (!loginTradeNo || loginTradeNo == '') {
          if (response.data.error == 'DUPLICATE') {
            this.$alert(
              'PASS 본인 인증에 사용하신 번호로 검색된 로그인ID(거래선번호)가 2개 이상 존재합니다. 다른 로그인 방식을 이용해 주십시오.<br>' +
                '만일 다른 로그인 방식을 이용하여도 로그인을 하지 못할 시에는 영업담당자에게 문의해 주시길 바랍니다.'
            );
          } else {
            this.$alert(
              '선택하신 번호로 등록된 계정 정보를 찾지 못했습니다.<br/>다른 방법으로 로그인 해주시기 바랍니다.'
            );
          }
          return;
        }

        this.kmCertPem = response.data.kmCertPem;
        this.loginToken = response.data.loginToken;

        let loginData = loginTradeNo + ':' + passNo + ':' + this.loginToken;
        console.log('loginData=' + loginData);

        // 전달 인자 param 설정
        let param = {
          passNo: passNo.toString(),
          loginToken: this.loginToken,
          data: loginData,
        };

        const pgmCd = document.getElementsByName('pgmCd')[0].value;

        const formData = {
          pgmCd: pgmCd,
          id: loginTradeNo,
          password: '(!pass!)' + JSON.stringify(param),
          page: this.home,
          tradeNo:oldTradeNo,
          gjdeInit : (oldTradeNo.length > 7)?'N':'Y',
        };

        this.loading = true;

        await this.doLogin(formData);

      } else {
        this.$alert('PASS 본인 인증에 실패하였습니다.');
        return;
      }
    },

    // 공동인증서 로그인
    async onSecuKitLogin(event, selectedTradeNo) {

      this.$secuKit.checkReady();

      if (!selectedTradeNo) {
        selectedTradeNo = '';
      }
      let businessNo = document.getElementById('inputBusinessNo').value;
      let personId = document.getElementById('personId1').value + document.getElementById('personId2').value;
      let selectIndex = document.getElementById('rcert1').checked ? 1 : 2;

      if (selectIndex == 1) {
        if ((businessNo == '' || businessNo.length != 10) && selectIndex == 1) {
          this.$alert(' 사업자 번호를 입력해주세요.1');
          return;
        }
      } else {
        if ((personId == '' || personId.length != 13) && selectIndex == 2) {
          this.$alert('검색하실 주민등록번호를 입력해주세요.');
          return;
        }
        if (!this.isValid_jumin(personId)) {
          this.$alert('주민등록번호를 정확히 입력해주세요.');
          return;
        }
      }

      var ssn = '';

      if (selectIndex == 1) {
        ssn = businessNo;
      } else if (selectIndex == 2) {
        ssn = personId;
      }

      let response = await axios.post('/userInfo/preCheckLogin', {
        type: 'pki',
        data: ssn,
        loginTradeNo: selectedTradeNo,
      });

      var loginTradeNo = response.data.tradeNo;
      var oldTradeNo = response.data.oldTradeNo; //GJDE
      console.log("loginTradeNo:",loginTradeNo);
      console.log("oldTradeNo:",response);
      if ((!loginTradeNo || loginTradeNo == '') && response.data.error == 'DUPLICATE') {
        this.tradeNoList = response.data.data;
        var isOk = false;
        //이미 신거래선이 생성된거면 7자리는 지운다
        var tradeNoList_T = this.tradeNoList;      
        if ( this.tradeNoList.length > 0) {
          tradeNoList_T = this.tradeNoList.filter(function (x){
            return (x.count == 1 || (x.count > 1 && x.tradeNo==x.oldTradeNo));
          });
          this.tradeNoList = tradeNoList_T;
          console.log("tradeNoList_T",tradeNoList_T);
        }

        if (selectedTradeNo != '') {
          for (var i = 0; i < this.tradeNoList.length; i++) {
            if (this.tradeNoList[i].tradeNo == selectedTradeNo) {
              oldTradeNo = this.tradeNoList[i].oldTradeNo; //GJDE
              console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaa",this.tradeNoList[i].oldTradeNo)
              isOk = true;
              break;
            }
          }
        }
        
        if (isOk) {
          loginTradeNo = selectedTradeNo;
        } else {
          this.selectTradeNo();
          return;
        }
      }

      if (!loginTradeNo || loginTradeNo == '') {
        if (response.data.error == 'DUPLICATE') {
          this.$alert(
            '입력하신 내용으로 검색된 로그인ID(거래선번호)가 2개 이상 존재합니다. 다른 로그인 방식을 이용해 주십시오.<br>' +
              '만일 다른 로그인 방식을 이용하여도 로그인을 하지 못할 시에는 영업담당자에게 문의해 주시길 바랍니다.'
          );
        } else {
          this.$alert(
            '선택하신 공동인증서로 등록된 계정 정보를 찾지 못했습니다.<br/>다른 방법으로 로그인 해주시기 바랍니다.'
          );
        }
        return;
      }
      console.log('loginTradeNo=' + loginTradeNo);

      this.kmCertPem = response.data.kmCertPem;
      this.loginToken = response.data.loginToken;

      console.log(JSON.stringify(response.data));

      // 인증서 툴킷 init
      let checkReady = this.$secuKit.secuKitReady;
      console.log('checkReady = ' + checkReady);

      if (!checkReady) {
        this.$alert('공동인증서 툴킷 로드에 실패하였습니다.');
        return;
      }

      const r1 = await this.$secuKit.showDialog();
      console.log('r1', r1);

      const certID = this.$secuKit.certListInfo.getCertID();
      console.log('certID', certID);

      let data1 = {
        certType: 'SignCert',
        certID: certID,
        isViewVID: '1', // 0 : VID 추출 안함,  1 : VID 추출
      };

      // 인증서 정보 받아오기
      const r2 = await this.$secuKit.invoke('viewCertInfomationWithVID', data1);
      console.log('r2 :', r2);

      let UserSignCert = r2.certPEM; // SignCert 정보 (--begin~~~)
      let vidRandom = r2.vidRandom; // 신원확인 정보 rvalue

      console.log('vidRandom:', vidRandom);

      let data2 = {
        algorithm: 'SEED/CBC/PADDING', // 암호화 알고리즘
        keyName: certID,
        sourceString: vidRandom,
      };

      const r3 = await this.$secuKit.invoke('symmetricEncryptData', data2);
      console.log('r3 :', r3); //r3.symmetricEncryptData; // 신원확인 정보를 대칭키 암호화

      let data3 = {
        serverCert: removePEMHeader(removeCRLF(this.kmCertPem)), // 서버 인증서 정보
        rsaVersion: 'V15', // RSA 버전 (V15 / V21)
        keyName: certID,
      };

      const r4 = await this.$secuKit.invoke('getSymmetricKeyIV', data3);
      console.log('r4 :', r4); //r4.getSymmetricKeyIV; // 서버 인증서로 대칭키 암호화에 사용된 키 RSA 암호화 추 추출

      this.Identify.UserSignCert = UserSignCert;
      this.Identify.EncryptedSessionKey = r4.getSymmetricKeyIV;
      this.Identify.EncryptedUserRandomNumber = r3.symmetricEncryptData;

      // 입력값 서명

      let signBusinessNoData = {
        certType: 'SignCert',
        sourceString: loginTradeNo + ':' + ssn + ':' + this.loginToken,
        certID: certID,
      };
      console.log("signBusinessNoData:",signBusinessNoData);
      const b1 = await this.$secuKit.invoke('generatePKCS7SignedData', signBusinessNoData);
      console.log("b1:",b1);

      this.Identify.SecuKit_PKCS7SignedMessage = b1.generatePKCS7SignedData;

      // 전달 인자 param 설정
      let param = {
        ssn: ssn,
        loginToken: this.loginToken,
        encryptedSessionKey: this.Identify.EncryptedSessionKey,
        encryptedUserRandomNumber: this.Identify.EncryptedUserRandomNumber,
        signedMessage: this.Identify.SecuKit_PKCS7SignedMessage,
      };

      const pgmCd = document.getElementsByName('pgmCd')[0].value;

      const formData = {
        pgmCd: pgmCd,
        id: loginTradeNo,
        password: '(!pki!)' + JSON.stringify(param),
        page: this.home,
        tradeNo:oldTradeNo,
        gjdeInit : (oldTradeNo.length > 7)?'N':'Y',
      };

      this.loading = true;

      await this.doLogin(formData);


      // 					//	본인 인증
      // 			let response2 = await axios.post('/userInfo/identifyUserCert', param);
      // 	       	console.log("identifyUserCert : ",response2);

      // 			if (response2.data.bolVerify == true) {
      // 				this.Identify.Message = response2.data.subjectDN + '\n' + response2.data.serialNumber;
      // 				this.$alert("공동인증서 본인인증에 성공하였습니다.");
      // 				this.isSignFind = true;
      // 				this.identifyComplete = true;
      // 			} else {
      // 				this.Identify.Message = response2.data.errorMessage;
      // 				this.$alert("공동인증서 본인인증에 실패하였습니다.\n "+ this.Identify.Message);
      // 			}
      // if(true) return;
    },
    //GJDE
    async gjdeInitLogin (event, selectedGjdeTradeNo) {
      const tradeNo = $.trim(document.getElementsByName('id')[0].value);
      
      console.log("selectedGjdeTradeNo",selectedGjdeTradeNo);
      // const formData = this.getFormData();
      document.getElementsByName('id')[0].value = selectedGjdeTradeNo;
      
      var objid = document.getElementsByName('id')[0];
      objid.value = $.trim(objid.value);
      const pgmCd = document.getElementsByName('pgmCd')[0].value;
      const id = objid.value;
      const password = document.getElementsByName('password')[0].value;

      const formData = {
        pgmCd: pgmCd,
        id: id,
        password: password,
        page: this.home,
        gjdeInit : 'Y' ,
        tradeNo: tradeNo,
      };

      this.loading = true;

      const listTradeItem  = await this.doLogin(formData);
      this.gjdeTradeNoList = listTradeItem;
      //GJDE 거래선 자리수 체크
      //console.log("this.gjdeTradeNoList",this.gjdeTradeNoList);
      //console.log("this.gjdeTradeNoList.length",this.gjdeTradeNoList.length);
      if(typeOf (this.gjdeTradeNoList) != undefined && this.gjdeTradeNoList.length > 0){
        this.selectGjdeTradeNo();
        return;
      }
    },
    idFind() {
      this.$modal.show('idFind');
    },
    pwFind() {
      this.searchId = '';
      this.$modal.show('pwFind');
    },
    selectTradeNo() {
      this.$modal.show('selectTradeNo');
    },
    //GJDE
    selectGjdeTradeNo() {
      this.$modal.show('selectGjdeTradeNo');
    },
    openManual() {
      var win = window.open(this.webManualUrl, 'manualwin', 'width=1200,height=800,resizable=on');
      win.focus();
    },
    isValid_jumin(jumin) {
      var juminStr = jumin.toString();
      var a = juminStr.substring(0, 1);
      var b = juminStr.substring(1, 2);
      var c = juminStr.substring(2, 3);
      var d = juminStr.substring(3, 4);
      var e = juminStr.substring(4, 5);
      var f = juminStr.substring(5, 6);
      var g = juminStr.substring(6, 7);
      var h = juminStr.substring(7, 8);
      var i = juminStr.substring(8, 9);
      var j = juminStr.substring(9, 10);
      var k = juminStr.substring(10, 11);
      var l = juminStr.substring(11, 12);
      var m = juminStr.substring(12, 13);
      var month = juminStr.substring(2, 4);
      var day = juminStr.substring(4, 6);
      var juminStr1 = juminStr.substring(0, 7); //앞의 자리
      var juminStr2 = juminStr.substring(7, 13); //뒤의 자리

      // 월,일 Validation Check
      if (month <= 0 || month > 12) {
        return false;
      }
      if (day <= 0 || day > 31) {
        return false;
      }

      // 주민등록번호에 공백이 들어가도 가입이 되는 경우가 발생하지 않도록 한다.
      if (isNaN(juminStr1) || isNaN(juminStr2)) {
        return false;
      }

      var temp = a * 2 + b * 3 + c * 4 + d * 5 + e * 6 + f * 7 + g * 8 + h * 9 + i * 2 + j * 3 + k * 4 + l * 5;
      temp = temp % 11;
      temp = 11 - temp;
      temp = temp % 10;

      if (temp == m) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    loading(newValue, oldValue) {
      if (newValue == true) {
        $("#divLoadingImage").show();
      }
    },
  }, //
};
</script>

<style scoped>
.joinStep {
  position: relative;
  left: 480px;
  width: 100px;
  height: 50px;
  background-color: darkviolet;
  color: antiquewhite;
  font-weight: bold;
}
</style>
